<template>
    <div class="bg-black/70  absolute right-0 z-[400] rounded-l-md" :class="!panel ? 'hidden' : `${classButtom, classWidth, classTop}` ">
        <div @click="$emit('close')" :class="`${classMarginleftIcon}`" class="text-white text-2xl cursor-pointer w-10"><i class="ri-close-line"></i></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        panel: {
            type: Boolean,
        },
        classButtom: {
            type: String,
            default: 'bottom-[200px]'
        },
        classWidth: {
            type: String,
            default: 'w-[500px]'
        },
        classTop: {
            type: String,
            default: 'top-[90px]'
        },
        classMarginleftIcon: {
            type: String,
            default: 'ml-[490px]'
        }
    }
}
</script>