<template><div></div></template>

<script>
import L from 'leaflet';
import distance from '@/util/distanceLine.js'
import green from '@/assets/img/pin-green.png'
import orange from '@/assets/img/pin-orange.png'
import red from '@/assets/img/pin-red.png'
export default {
    props:['map'],
    data() {
        return {
            router_active: false,
            routers: [{
                markerObject: [],
                routerObject: [],
                routerDistance: [],
                routerLine: [],
                parameterDistance: {
                    distance: null
                },
            }],
            is_number: 0,
        }
    },
    methods: {
        showRouter() {
            this.router_active = true
            this.map.on('click', (e) => {
                this.router_active ? this.createRouter(e) : ''
            })
        },
        createRouter(e) {
            if(e.latlng !== undefined) this.routers[0].routerLine.push({id: ++this.is_number, coords: [e.latlng.lat, e.latlng.lng]})
            this.createMarker()
            if(this.routers[0].routerLine.length > 1) this.routerCreate()
            this.$emit('dataRouter', this.routers, this.is_number)
        },
        createMarker() {
            this.removeMarker()
           
            this.routers[0].routerLine.forEach((e, index) => {
                let customIcon;
                if (e.id == 1) {
                    customIcon = `<div style="position: relative;"><div style="position: absolute; left: 10px; top: 5px">${++index}</div><img src="${green}"></div>`;
                } else if (e.id == this.is_number) {
                    customIcon = `<div style="position: relative;"><div style="position: absolute; left: 10px; top: 5px">${++index}</div><img src="${red}"></div>`;
                } else {
                    customIcon = `<div style="position: relative;"><div style="position: absolute; left: 10px; top: 5px">${++index}</div><img src="${orange}"></div>`;
                }
                const marker = L.marker([e.coords[0], e.coords[1]], {
                    draggable: true,
                    icon: L.divIcon({
                        html: customIcon,
                        className: ''
                    }),
                }).addTo(this.map)
                this.routers[0].markerObject.push(marker)
                this.deleteAndDrag(marker, e.id)
            });
        },
        async routerCreate() {
            await distance(this.routers[0].routerLine)
            .then((res) => {
                this.routers[0].parameterDistance.parametersRouter = res.data.parametersRouter   
                this.routers[0].routerDistance = []
                res.data.route.forEach((e) => {this.routers[0].routerDistance.push([e[1], e[0]])})
                this.removeRouter()
                this.createLine()
            })
            .catch((err) => {
                console.log(err);
            })            
        },
        createLine() {
            let polyline = L.polyline(this.routers[0].routerDistance).addTo(this.map)
            polyline.setStyle({
                color: 'blue',
                weight: 8,
                opacity: 0.5
            });

            this.routers[0].routerObject.push(polyline)
        },
        deleteAndDrag(marker, id) {
            let data = this.routers[0]
            marker.on('click', () => {
                data.routerLine = data.routerLine.filter(ele => ele.id != id);
                this.createMarker()
                this.routerCreate()
            })
            marker.on('dragend', (e) => {
                data.routerLine.forEach((ele) => {
                    if(ele.id == id) {
                        ele.coords = [e.target._latlng.lat, e.target._latlng.lng]
                        this.createMarker()
                        this.routerCreate()
                        this.$emit('dataRouter', this.routers)
                    }
                    
                })
            })
        },
        removeMarker() {
            this.routers[0].markerObject.forEach((e) => {
                if(e) {
                    e.remove()
                    this.routers[0].markerObject = []
                }
            })
        },
        deleteMarker(dataMarker) {
            this.routers[0].routerLine = dataMarker
            if(this.routers[0].routerLine.length == 1) {
                this.removeRouter()
                console.log(                this.parameterDistance);
                
                this.createMarker()
            }else if(this.routers[0].routerLine.length > 1) {
                this.createMarker()
                this.routerCreate()
            }else {
                this.removeMarker()
            }
            
            this.$emit('dataRouter', this.routers)
        },
        showReturnPath(data) {
            this.deleteMarker(data)
        },

        addMarkerToTheMap(data) {
            this.deleteMarker(data)
        },

        addCenterMarkerVillage(data) {
            this.deleteMarker(data)
        },
        removeRouter() {
            this.routers[0].routerObject.forEach((e) => {
                if(e) {
                    e.remove()
                    this.routers[0].routerObject = []
                }
            })
        },
        clearRouter() {
            this.router_active = false,
            this.routers = [{
                markerObject: [],
                routerObject: [],
                routerDistance: [],
                routerLine: []
            }],
            this.is_number = 0
        }
    },
}
</script>