<script>
export default {
    props: {
        displayText: String
    },
    computed: {
        handlers () {
			const keys = Object.keys(this.$listeners)
			const handlers = {}
			keys.forEach(k => (handlers[k] = e => this.$emit(k, e)))
			return handlers
		},
    }
}
</script>

<template>
    <button class="border w-[120px] text-sm text-black px-2 py-1 rounded-[2px] bg-white cursor-pointer ml-[120px]" v-on="handlers">
        {{ displayText }}
    </button>
</template>