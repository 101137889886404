import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import '@/assets/css/tailwind.css'
import '@/assets/css/style.css'
import 'remixicon/fonts/remixicon.css'
import router from './router'
import {store} from './store'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css";
import VueHotkey from 'v-hotkey'
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';



axios.defaults.baseURL = 'https://api-3taxi.gram.tj/api/';
// axios.defaults.baseURL = 'https://test-admin.gram.tj/api/';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('user-token');
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

Vue.component('v-select', vSelect)
Vue.config.productionTip = false
Vue.use(VueToast);
Vue.use(VueHotkey)
Vue.use(VueAxios, axios)

new Vue({
  router,
  axios,
  store,
  render: h => h(App),
}).$mount('#app')
